import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as datepicker from 'datepicker';

import * as Lazy from 'jquery-lazy';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($lightgallery.length) {
		$(".section--gallery").lightGallery({
			selector: '.lightgallery' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	$("img[data-src], div[data-src], a[data-src]").Lazy({
		effect: "fadeIn",
        effectTime: 200,
        enableThrottle: true,
        throttle: 250,
        threshold: 400
	});

	$('body').on('click', '.scroll-to', function() {
		var topPos = 0,
			$self = $(this);

		var href = $self.attr('href');
		var target = $self.attr('data-target');
		if(href.charAt(0) === '#') { target = href; }

		topPos = $(target).offset().top - $('.header').height()-20;

		$('html,body').animate({ scrollTop: topPos}, 1000);

		return false;
	});

	if($map) {
		googleMap.init();
	}

	$('#resform .from').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCiD').val(spl[2]);
            $('#resform .qCiMy').val(month + '' + spl[0]);

            var min_date = new Date(da);
            $('#resform .to').datepicker('option', 'minDate', min_date);
        },
        minDate: new Date(),
    });

    $("#resform .from").datepicker("setDate", new Date());

    $('#resform .to').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCoD').val(spl[2]);
            $('#resform .qCoMy').val(month + '' + spl[0]);
        }
    });

    var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);

    $("#resform .to").datepicker("setDate", tomorrow);

	[].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
	  img.setAttribute('src', img.getAttribute('data-src'));
	  img.onload = function() {
	    img.removeAttribute('data-src');
	  };
	});

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

	$('.btn--form-stationary').on('click', function(){
		// var title = $(this).parent().parent().find('h2').text(),
		// 	title2 = $('#section1').find('.col-centered').find('h2').text();

		// if(title.length){
		// 	$('.title-offer').val(title);
		// } else if(title2.length) {
		// 	$('.title-offer').val(title2);
		// }

		$('.popup-hld--form').addClass('js-show');
	});

	$('.btn--form-online').on('click', function(){
		// var title = $(this).parent().parent().find('h2').text(),
		// 	title2 = $('#section1').find('.col-centered').find('h2').text();

		// if(title.length){
		// 	$('.title-offer').val(title);
		// } else if(title2.length) {
		// 	$('.title-offer').val(title2);
		// }

		$('.popup-hld--form-online').addClass('js-show');
	});

	if($(window).width() < 830) {
		$('#overlay-menu').find('.menu-item-has-children > a').on('click', function(e){
			$(this).parent().toggleClass('active');

			$(this).parent().find('.sub-menu').slideToggle();
			
			e.stopPropagation();
			e.stopImmediatePropagation();
		});
	} 
});

// $(window).on('load', function() {
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

// });